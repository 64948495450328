.carousel .item-container {
    position: relative;
}

.carousel .item-container .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
}

.carousel .item-container .overlay .content {
    padding: 30px;
    overflow-wrap: break-word;
}

.carousel .item-container .overlay .content hr.date {
    border-top-color: white;
    width: 50%;
    margin: 15px auto;
}

.carousel .item-container .overlay .content hr.location {
    border-top-color: white;
    width: 35%;
    margin: 15px auto;
}

.carousel .item-container .overlay .learn-more {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 1.05em;
    background-color: rgba(0, 0, 0, 0.6);
}

.carousel .item-container .overlay .learn-more .learn-more-link {
    color: white;
}

.carousel .item-container .overlay .learn-more .learn-more-link i {
    transition: transform 0.2s ease-out;
}

.carousel .item-container .overlay .learn-more:hover .learn-more-link i {
    transform: translateX(10px);
}