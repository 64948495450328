.loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    z-index: -1;
    opacity: 0;
}

.loading-spinner.visible {
    opacity: 1;
    z-index: 1051;
}

.loading-spinner:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: var(--primary-color) transparent var(--teritiary-color) transparent;
    animation: loading-spinner 1.2s linear infinite;
}
@keyframes loading-spinner{
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
