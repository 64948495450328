.more-options-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 400px;
    text-align: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    z-index: 4;
}

.fab {
    width: 55px;
    height: 55px;
    color: white;
    background-color: var(--primary-color);
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-bottom: 10px;
}

.fab-icon {
    font-family: "Font Awesome 5 Brands";
}

.fab-options .option-item {
    display: none;
    border: none;
}

.fab-options a.option-item {
    padding-top: 19px;
}

.fab-options.hide-items .option-item {
    animation: 0.5s fadeOutUp ease-out both;
}

.fab-options.show-items .option-item {
    animation: 0.5s fadeInUp ease-out both;
}

.fab:hover {
    color: white;
    background-color: var(--primary-hover-color);
}

#more-options.open i::before {
    content: "\f00d";
}
