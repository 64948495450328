.navbar {
    display: table;
    width: 100%;
    text-align: center;
    position: fixed;
    z-index: 4;
    transition: all 0.5s;
}

.navbar .navbar-toggler {
    position: relative;
    height: 30px;
}

.navbar .navbar-toggler:focus {
    outline: none;
}

.navbar .navbar-content {
    margin: auto;
}

.navbar .navbar-content .navbar-brand {
    /*display: table;*/
}

.navbar-nav {
    margin: auto;
}

.navbar-nav .nav-link {
    color: white;
}

.navbar .navbar-brand {
    color: white;
}

.navbar-nav .nav-link:hover + .slider {
    transform: scaleX(1);
}

.navbar-nav .nav-item {
    padding-left: 10px;
    padding-right: 10px;
    animation-duration: 0.5s;
    transition: all 0.5s;
}

.vertical-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    background-color: var(--primary-color);
    opacity: 0.95;
    z-index: 999;
    transition: all 0.5s ease-out;
}

.vertical-nav:hover {
    opacity: 1;
}

.vertical-nav .navbar-nav {
    margin-top: 30px;
    text-align: left;
}

.vertical-nav .navbar-nav .nav-item {
    position: relative;
    display: table;
    margin-left: 10px;
    margin-right: 10px;
    background-color: transparent;
    border-bottom: 0.5px solid rgba(31, 31, 31, 0.4);
}

.navbar-nav .slider {
    display: block;
    width: 100%;
    transform-origin: center;
    transform: scaleX(0);
    height: 2px;
    background-color: white;
    transition: all 0.4s ease-out;
}

.navbar-collapse {
}

.navbar .navbar-toggler:active {
    border: none;
}

.navbar .navbar-toggler span {
    width: 100%;
    height: 2px;
    width: 30px;
    background-color: white;
    display: block;
    position: absolute;
    right: 0;
    transform-origin: center;
    transition: all 0.2s;
}

.navbar .navbar-toggler span:nth-child(1) {
    top: 0px;
}

.navbar .navbar-toggler span:nth-child(2) {
    top: 8px;
}

.navbar .navbar-toggler span:nth-child(3) {
    top: 16px;
}

.navbar .navbar-toggler:not(.collapsed) span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.navbar .navbar-toggler:not(.collapsed) span:nth-child(2) {
    width: 0;
}

.navbar .navbar-toggler:not(.collapsed) span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

@media only screen and (max-width: 1199px) {
    .navbar {
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: var(--primary-color);
    }

    .navbar-nav .nav-item {
        animation: 0.5s fadeInLeft ease-out both;
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
    }
}




