:root {
    --primary-color: #257896;
    --primary-hover-color: #2e93b8;
    --secondary-color: #1CBCAF;
    --teritiary-color: #7DC355;
    --facebook-color: #3b5998;
    --twitter-color: #1da1f2;
    --linkedin-color: #0077b5;
    --instagram-color: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    --showcase-picture-height: 50vh;
}

@font-face {
    font-family: 'Optima';
    font-style: normal;
    font-weight: normal;
    src: local('Optima'), url('/assets/font/OPTIMA.woff') format('woff');
}


@font-face {
    font-family: 'Optima Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Optima Italic'), url('/assets/font/Optima_Italic.woff') format('woff');
}


@font-face {
    font-family: 'Optima Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Optima Medium'), url('/assets/font/Optima Medium.woff') format('woff');
}


@font-face {
    font-family: 'Optima Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Optima Bold'), url('/assets/font/OPTIMA_B.woff') format('woff');
}

* {
    font-family: 'Optima', Helvetica, sans-serif;
    font-weight: lighter;
}

html {
    margin: 0;
    padding: 0;
    background-color: #efefef;
}

body {
    background-color: #efefef;
}

hr {
    margin: 50px 0 50px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

a {
    color: var(--primary-color);
}

a:hover {
    text-decoration: none;
    color: var(--primary-hover-color);
}

.bold-headline {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    margin-bottom: 50px;
    color: var(--primary-color);
}

.margin-auto {
    margin: auto;
}

.row {
    margin-left: 0;
    margin-right: 0;
}
.row-media{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
}

.section {
    margin-bottom: 75px;
    transition: all 0.2s ease-in;
}

a.section-link {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.navbar-brand {
    margin: 20px 0 20px 0;
}

#body-overlay {
    z-index: -1;
    background-color: rgba(31, 31, 31, 1);
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s;
}

.container {
    max-width: 80%;
    min-height: 60%;
    padding: 50px 30px 50px 30px;
    margin: auto auto 30px auto;
    color: var(--primary-color);
}

.container-with-page-nav #heading-container {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.btn {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.10rem;
}

.btn:focus {
    box-shadow: none;
}

.btn.primary-bg:hover {
    color: white;
    background-color: var(--primary-hover-color);
}

.primary-bg {
    background-color: var(--primary-color);
    color: white;
}

.spaced-header {
    letter-spacing: 0.25rem;
    margin: 0;
}

.showcase {
    top: 0;
    left: 0;
    color: white;
    width: 100%;
    position: relative;
    overflow: hidden;
    min-height: 400px;
}

.showcase .overlay {
    height: 100%;
    width: 100%;
    padding: 30px;
}

.showcase .overlay.grayscale {
    background-color: rgba(0, 0, 0, 0.2);
}

.logo-title {
    padding: 10px;
    display: table;
    margin: auto;
    border-left: 1px solid white;
}

#overlay-content {
    padding: 15px 15px 0 15px;
    position: absolute;
    bottom: 30%;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 2;
}

#banner-media {
    position: absolute;
    left: 0;
    z-index: 1;
    height: auto;
    width: 100%;
}

.overlay-title span {
    opacity: 0;
    display: inline-block;
    animation: fadeInUp 1s both;
    -webkit-animation: fadeInUp 1s both;
}

.figure-caption {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 90px;
}

.figure-caption .title {
    font-weight: bold;
    color: var(--primary-color);
}

.modal-content {
    padding: 30px;
}

.modal-header, .modal-header .close {
    border: none !important;
    color: var(--primary-color);
    outline: none;
}

.nav-pills .nav-link {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    z-index: 3;
    border-left: 5px solid transparent;
}

.nav-pills .nav-link.active {
    background-color: transparent;
    color: var(--primary-color);
    opacity: 1;
}

.nav-pills .nav-link:hover:not(.active) {
    opacity: 1;
    border-left-color: var(--teritiary-color);
}

.accordion {
    padding: 10px;
    background-color: white;
    border-radius: 4px;
}

.accordion .card {
    border: none;
}

.accordion .card .card-header {
    background-color: inherit;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 0;
}

.accordion .card .card-header .btn {
    width: 100%;
    text-align: left;
    color: var(--primary-color);
}

.ck-editor__editable {
    min-height: 350px !important;
}

.more-info-button {
    color: var(--primary-color);
    padding: 0;
    text-decoration: none !important;
}

.more-info-button i {
    transition: transform 0.2s ease-out;
    padding: 5px !important;
}

.more-info-button:hover {
    color: var(--primary-hover-color);
}

.more-info-button:hover i {
    transform: translateX(10px);
}
.btn-link {
    padding: 0;
}

/* Home */
#home-container .card-content:hover {
    transform: translateY(-15px) !important;
}

#home-container .section .card-content {
    padding: 30px;
    transition: all 0.2s ease-out;
}

#home-container #section-1 {
    background-color: white;
}

#home-container #section-2 {
    border-bottom: 1px solid var(--secondary-color);
}

#home-container #section-3 {
    background-color: var(--teritiary-color);
    color: white;
}

#home-container #section-4 {
    background-color: var(--secondary-color);
    color: white;
}

#home-container .section .next-link-container {
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
}

#home-container .section .next-link {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    right: 0;
    bottom: 0;
    color: black;
    border-radius: 50%;
    background-color: white;
}

#home-container .section i {
    padding: 16px;
}

/* About Us */
#about-us-container .row .col-md-3 {
    padding-left: 0;
    padding-right: 30px;
}

#about-us-banner {
    background: url("/assets/images/about_us.jpg") no-repeat center;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

#about-us-overlay-content{
    bottom: 15%;
    padding: 15px 15px 0 15px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 2;
}

/* Services */
#services-banner {
    background: url("/assets/images/services_banner.jpg") no-repeat center;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

#services-container .row h2 {
    margin-bottom: 30px;
}

#services-overlay-content {
    bottom: 15%;
    padding: 15px 15px 0 15px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 2;
}

/* Assessments */
#assessments-banner {
    background: url("/assets/images/psych_assessment.jpg") no-repeat;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

#v-pills-tab-assessments a {
    padding: 20px;
}

#v-pills-tabContent-assessments{
    margin-left: 2rem;
}

/* Resources */
#resources-banner {
    background: url("/assets/images/resources.jpg") no-repeat center;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

/* Contact us */
#contact-us-banner {
    background: url("/assets/images/contact_us.jpg") no-repeat center;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

#contact-us-container .location-address-container {
    margin-right: auto;
}

#contact-us-container .location-address-container .location-address {
    margin-top: 30px;
    margin-bottom: 30px;
}

#contact-us-container .location-map {
    margin-right: auto;
}

/* Coaching and Training */
#coaching-banner {
    background: url("/assets/images/coaching.jpg") no-repeat;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

#training-banner {
    background: url("/assets/images/executive_training.jpg") no-repeat 50%;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

/* News and Events */
#news-events-banner {
    background: url("/assets/images/news_events.jpg") no-repeat;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

#news-events-container .card {
    margin: 30px auto 30px auto;
    padding: 20px;
    color: white;
    text-align: left;
}

#news-events-container .card:hover {
    transform: translateY(-15px);
}

#news-events-container .card .type-header {
    font-size: 15px;
    letter-spacing: 0.15em;
}

#news-events-container .card.news {
    background-color: var(--secondary-color);
}

#news-events-container .card.blog {
    background-color: var(--teritiary-color);
}

#posting-container #author-date {
    letter-spacing: 0.15rem;
}

/* Groups and Workshops */
#groups-workshops-banner {
    background: url("/assets/images/groups_workshops.jpg") no-repeat;
    background-size: cover;
    height: var(--showcase-picture-height);
    overflow: visible;
}

#groups-workshops-container .workshop-content hr {
    margin: 20px;
}

/* Footer */
.footer {
    background-color: var(--teritiary-color);
    color: white;
}

.footer hr {
    border-top-color: white;
    margin: 20px 0 20px 0;
}

.footer .spaced-header {
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-weight: bold;
    color: #ededed;
}

.footer .list-group {
    margin-top: 5px;
}

.footer .list-group-item {
    background-color: var(--teritiary-color);
    border: none;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 0.95em;
}

.footer a {
    color: white;
}

.footer a:hover {
    text-decoration: underline;
}

.footer .legal-info {
    margin-top: 5px;
    font-size: 0.95em;
}

.footer .legal-info .legal-info-item {
    display: inline-block;
    padding-right: 15px;
    margin-right: 15px;
    margin-top: 5px;
    border-right: 1px solid white;
}

.footer .social-media {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;
}

.footer .social-media .link {
    margin-right: 20px;
    opacity: 0.75;
}

.footer .social-media .link:hover {
    margin-right: 20px;
    opacity: 1;
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
    .team-member-picture {
        max-height: 160px;
    }

    .showcase {
        height: 500px !important;
    }

    #about-us-container #who-we-are {
        width: 80%;
        margin: auto;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        max-width: 100%;
    }

    .footer .container {
        width: 99%;
    }

    .showcase {
        height: 400px !important;
    }

    #about-us-container #leadership-members {
        text-align: center;
    }

    #about-us-container #who-we-are {
        margin-top: 40px;
    }

    .figure-caption {
        min-height: auto;
    }
}

@media only screen and (max-width: 767px) {
    #banner-media {
        left: -100px;
        height: 400px;
        width: auto;
    }

    .container-with-page-nav #heading-container {
        width: 100%;
    }

    .figure-caption {
        min-height: auto;
    }

    .team-member-picture {
        width: 320px;
    }
}

@media only screen and (max-width: 1199px) {
    #overlay-content {
        bottom: 0%;
        top: 20px;
    }    
}

@media only screen and (max-width: 400px) {
    .card h6 {
        font-size: 0.7rem;
    }
}
