#floating-page-nav-container {
    position: absolute;
    left: 30px;
    margin-top: 35px;
    background-color: var(--secondary-color);
    color: white;
    z-index: 3;
    padding: 25px 20px 25px 30px;
    box-shadow: rgba(31, 31, 31, 0.3) 8px 8px 10px;
    border-radius: 4px;
}

#floating-page-nav-container .btn {
    font-size: 14px;
    color: white;
    margin: 0;
    padding: 0;
}

#floating-page-nav-container .btn i {
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg);
}

.rotate-45deg {
    transform: rotate(45deg);
}

#floating-page-nav-container ul li {
    transition: all 0.35s ease-out;
    background-color: var(--secondary-color);
    border: none;
    max-width: 200px;
    pointer-events: auto;
}

#floating-page-nav-container ul li a {
    color: white;
}

#floating-page-nav-content {
    max-height: 500px;
    transition: max-height 0.5s ease-out;
}

@media only screen and (min-width: 1199px) {
    #floating-page-nav-wrapper {
        position: sticky;
        position: -webkit-sticky;
        top: 143px;
        z-index: 4;
    }
}

@media only screen and (max-width: 1199px) {
    #floating-page-nav-content {
        max-height: 0;
    }

    #floating-page-nav-container .btn i {
        transform: rotate(0deg);
    }

    #floating-page-nav-container ul li {
        opacity: 0;
        animation-duration: 0.5s;
        animation-delay: 300ms;
        pointer-events: none;
    }
}